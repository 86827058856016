import Banner from 'src/components/Estaticos/Banner/Banner.vue'
import Beneficios from 'src/components/Estaticos/Beneficios/Beneficios.vue'
import MaisBuscados from 'src/components/Estaticos/MaisBuscados/MaisBuscados.vue'
import ElasticSearchModel from 'src/model/elasticsearch/ElasticSearchModel'
import VehicleCard from 'src/components/Card/VehicleCard.vue'
import { clone, LocalStorage, debounce } from 'quasar'
import Carregando from 'src/components/CarregandoGeral/Carregando'
import { EventBus } from 'src/core/event/EventBus'

export default {
  name: 'Veiculos',
  components: { Beneficios, MaisBuscados, Banner, Carregando, VehicleCard },
  meta () {
    return {
      title: this.titulo + ' - NetCarros',
      description: { name: 'description', content: 'Está procurando ' + this.titulo + '? Veja as oportunidades da plataforma Netcarros' },
      property: { 'name': 'og:image', content: 'https://www.netcarros.com.br/seo/netcarros-comprar-carro.jpg' },
      meta: [
        { 'property': 'og:title', 'content': this.titulo + ' - NetCarros' },
        { 'name': 'description', 'content': 'Está procurando ' + this.titulo + '? Veja as oportunidades da plataforma Netcarros' },
        { 'property': 'revisit-after', 'content': '5 days' },
        { 'property': 'og:image', 'content': 'https://www.netcarros.com.br/seo/netcarros-comprar-carro.jpg' },
        { 'property': 'og:image:secure_url', 'content': 'https://www.netcarros.com.br/seo/netcarros-comprar-carro.jpg' },
        { 'property': 'og:url', 'content': 'https://www.netcarros.com.br' + this.$route.fullPath },
        { 'property': 'og:site_name', 'content': 'Netcarros' },
        { 'property': 'fb:admins', 'content': '', 'vmid': 'fb:admins' },
        { 'property': 'og:locale', 'content': 'pt_BR' }
      ]
    }
  },
  data () {
    return {
      aggregations: {},
      faixa_parcela: [],
      faixa_valor: [],
      faixa_km: [],
      carregandoBusca: true,
      intervalMarcaModelo: 0,
      barraFiltros: false,
      showCleanFilterButton: false,
      linkpage: '',
      busca_geral: '',
      titulo: 'Carros usados e seminovos',
      categoria: 'todos',
      categorias: [],
      tags: [],
      quantidade: 0,
      qtdPorPagina: 29,
      kmInput: { min: '', max: '' },
      pcd: 0,
      pj: 0,
      filtroCarros: [
        { nome: 'Novos', ativo: 0 },
        { nome: 'Usados', ativo: 0 }
      ],
      sorting: -1,
      localizacao: '',
      moneyFormatForComponent: {
        decimal: ',',
        thousands: '.',
        prefix: ' R$ ',
        precision: 0,
        masked: false
      },
      marcasAlteracao: 0,
      marcasSelecionadas: [{
        nome: '',
        chave_interna: '',
        ativo: true,
        selecionado: 0,
        created_at: '2020-05-18 13:25:32',
        updated_at: '2020-05-18 13:25:32'
      }],
      modelosSelecionados: [],
      modelosSelecionadosAnterior: [],
      versoesSelecionadas: [],
      marcasPrincipais: [
      ],
      marcas: [
      ],
      modelos: [],
      versoes: [],
      marcasDisplay: [],
      paginaAtualMarcas: 0,
      fimPaginacaoMarcas: 0,
      precoToggle: 1,
      scrollX: 0,
      precoFiltro: {
        min: 28000,
        max: 145000
      },
      anoFiltro: {
        min: 2016,
        max: 2024
      },
      requisicoesBusca: [],
      dataInicializacao: Date.now(),
      alterandoPreco: false,
      alterandoKm: false,
      preco: {
        min: 30000,
        max: 70000
      },
      precoInput: {
        min: '',
        max: ''
      },
      preco_inicial: {
        min: 30000,
        max: 70000
      },
      valor_pf_inicial: {
        min: 30000,
        max: 70000
      },
      valor_pcd_inicial: {
        min: 30000,
        max: 70000
      },
      valor_pj_inicial: {
        min: 30000,
        max: 70000
      },
      valor_parcela_inicial: {
        min: 100,
        max: 30000
      },
      ano: {
        min: 2016,
        max: 2024
      },
      km: {
        min: 0,
        max: 1000000
      },
      portas: 4,
      cambios: [
        {
          nome: 'Manual',
          ativo: 0
        },
        {
          nome: 'Automático',
          ativo: 0
        },
        {
          nome: 'CVT',
          ativo: 0
        }
      ],
      opcionais: [
        {
          id: 1,
          grupo_acessorio_id: 1,
          nome: 'Airbag motorista',
          uri: 'airbag-motorista',
          foto: null,
          foto_url_completa: null,
          prioridade: 1,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:30',
          updated_at: '2020-07-03 10:29:08'
        },
        {
          id: 2,
          grupo_acessorio_id: 2,
          nome: 'Ar-condicionado',
          uri: 'ar-condicionado',
          foto: null,
          foto_url_completa: null,
          prioridade: 2,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:30',
          updated_at: '2020-07-03 10:29:25'
        },
        {
          id: 3,
          grupo_acessorio_id: 2,
          nome: 'Direção hidráulica',
          uri: 'direcao-hidraulica',
          foto: null,
          foto_url_completa: null,
          prioridade: 3,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:30',
          updated_at: '2020-07-03 10:30:03'
        },
        {
          id: 4,
          grupo_acessorio_id: 3,
          nome: 'Vidros elétricos dianteiros',
          uri: 'vidros-eletricos-dianteiros',
          foto: null,
          foto_url_completa: null,
          prioridade: 4,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:30',
          updated_at: '2020-07-03 09:20:02'
        },
        {
          id: 5,
          grupo_acessorio_id: 2,
          nome: 'Travas elétricas',
          uri: 'travas-eletricas',
          foto: null,
          foto_url_completa: null,
          prioridade: 5,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:30',
          updated_at: '2020-07-03 09:21:56'
        },
        {
          id: 6,
          grupo_acessorio_id: 2,
          nome: 'Retrovisores elétricos',
          uri: 'retrovisores-eletricos',
          foto: null,
          foto_url_completa: null,
          prioridade: 6,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:30',
          updated_at: '2020-07-03 10:30:36'
        },
        {
          id: 7,
          grupo_acessorio_id: 1,
          nome: 'Alarme',
          uri: 'alarme',
          foto: null,
          foto_url_completa: null,
          prioridade: 7,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:30',
          updated_at: '2020-07-03 10:30:52'
        },
        {
          id: 8,
          grupo_acessorio_id: 1,
          nome: 'Freios ABS',
          uri: 'freios-abs',
          foto: '/auto-2-you-app/eEPFBIAwr30N2Y4I-1592850875.png',
          foto_url_completa:
            'https://netcarros.nyc3.cdn.digitaloceanspaces.com/auto-2-you-app/eEPFBIAwr30N2Y4I-1592850875.png',
          prioridade: 8,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:30',
          updated_at: '2020-07-03 10:31:14'
        },
        {
          id: 9,
          grupo_acessorio_id: 4,
          nome: 'Bancos de couro',
          uri: 'bancos-de-couro',
          foto: null,
          foto_url_completa: null,
          prioridade: 9,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:30',
          updated_at: '2020-07-03 10:31:39'
        },
        {
          id: 10,
          grupo_acessorio_id: 1,
          nome: 'Blindado',
          uri: 'blindado',
          foto: null,
          foto_url_completa: null,
          prioridade: 10,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:30',
          updated_at: '2020-07-03 10:32:11'
        },
        {
          id: 11,
          grupo_acessorio_id: 1,
          nome: 'Airbag passageiro',
          uri: 'airbag-passageiro',
          foto: null,
          foto_url_completa: null,
          prioridade: 11,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:30',
          updated_at: '2020-07-03 10:33:08'
        },
        {
          id: 12,
          grupo_acessorio_id: 3,
          nome: 'Limpador traseiro',
          uri: 'limp-traseiro',
          foto: null,
          foto_url_completa: null,
          prioridade: 12,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:30',
          updated_at: '2020-07-03 09:55:58'
        },
        {
          id: 13,
          grupo_acessorio_id: 3,
          nome: 'Desembaçador traseiro',
          uri: 'desemb-traseiro',
          foto: null,
          foto_url_completa: null,
          prioridade: 13,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:30',
          updated_at: '2020-07-03 10:34:10'
        },
        {
          id: 14,
          grupo_acessorio_id: 2,
          nome: 'Câmbio automático',
          uri: 'cambio-automatico',
          foto: null,
          foto_url_completa: null,
          prioridade: 14,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:30',
          updated_at: '2020-07-03 10:34:33'
        },
        {
          id: 15,
          grupo_acessorio_id: 5,
          nome: 'Rodas de liga leve',
          uri: 'rodas-de-liga-leve',
          foto: null,
          foto_url_completa: null,
          prioridade: 15,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:30',
          updated_at: '2020-07-03 10:34:52'
        },
        {
          id: 16,
          grupo_acessorio_id: 5,
          nome: 'Tração 4x4',
          uri: 'tracao-4x4',
          foto: null,
          foto_url_completa: null,
          prioridade: 16,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:30',
          updated_at: '2020-07-03 10:44:58'
        },
        {
          id: 17,
          grupo_acessorio_id: 6,
          nome: 'Toca-fitas',
          uri: 'toca-fitas',
          foto: null,
          foto_url_completa: null,
          prioridade: 17,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:30',
          updated_at: '2020-07-03 10:45:08'
        },
        {
          id: 18,
          grupo_acessorio_id: 6,
          nome: 'Cd player',
          uri: 'cd-player',
          foto: null,
          foto_url_completa: null,
          prioridade: 18,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:30',
          updated_at: '2020-07-03 10:47:00'
        },
        {
          id: 19,
          grupo_acessorio_id: 5,
          nome: 'Kit gás',
          uri: 'kit-gas',
          foto: null,
          foto_url_completa: null,
          prioridade: 19,
          ativo: 0,
          aprovado: 0,
          created_at: '2020-05-18 13:25:30',
          updated_at: '2020-07-03 10:46:02'
        },
        {
          id: 20,
          grupo_acessorio_id: 3,
          nome: 'Teto solar',
          uri: 'teto-solar',
          foto: null,
          foto_url_completa: null,
          prioridade: 20,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:30',
          updated_at: '2020-07-03 10:45:15'
        }
      ],
      locais: [
        {
          'id': 8,
          'icarros_id': null,
          'situacao_local_id': 3,
          'grupo_local_id': null,
          'nome': 'Motos',
          'pai_local_id': null,
          'razao_social': 'NetCarros Motos',
          'cnpj': '06.288.221/0001-93',
          'arquivo_cnpj': null,
          'logomarca': null,
          'arquivo_cnpj_url_completa': null,
          'logomarca_url_completa': null,
          'uri': 'netcarros-motos',
          'estado': 'SP',
          'cidade': 'Av. Bandeirantes 2020',
          'bairro': 'Vila Campesina',
          'logradouro': 'Rua Deputado Em\u00edlio Carlos',
          'numero': null,
          'complemento': null,
          'cep': '06028-010',
          'numero_whatsapp': '11913675822',
          'numero_comercial': '11404088881',
          'numero_telefone_privado': '11404088881',
          'email_comercial': 'faleconosco@netcarros.com.br',
          'email_privado': 'faleconosco@netcarros.com.br'
        },
        {
          'id': 7,
          'icarros_id': null,
          'situacao_local_id': 3,
          'grupo_local_id': null,
          'nome': 'Estrada Fuku.Yida. - 1140A',
          'pai_local_id': null,
          'razao_social': 'NetCarros - S\u00e3o Bernardo',
          'cnpj': '09.509.505/0002-31',
          'arquivo_cnpj': null,
          'logomarca': null,
          'arquivo_cnpj_url_completa': null,
          'logomarca_url_completa': null,
          'uri': null,
          'estado': 'SP',
          'cidade': 'SBC, SP',
          'bairro': 'Cooperativa',
          'logradouro': 'Estrada Particular Fukutaro Yida',
          'numero': null,
          'complemento': null,
          'cep': '09852-060',
          'numero_whatsapp': '11913675822',
          'numero_comercial': '11404088881',
          'numero_telefone_privado': '11404088881',
          'email_comercial': 'faleconosco@netcarros.com.br',
          'email_privado': 'faleconosco@netcarros.com.br'
        },
        {
          'id': 6,
          'icarros_id': null,
          'situacao_local_id': 3,
          'grupo_local_id': null,
          'nome': 'Av. dos Bandeirantes, 2020',
          'pai_local_id': null,
          'razao_social': 'NetCarros - Bandeirantes',
          'cnpj': '34.350.715/0003-58',
          'arquivo_cnpj': null,
          'logomarca': null,
          'arquivo_cnpj_url_completa': null,
          'logomarca_url_completa': null,
          'uri': null,
          'estado': 'SP',
          'cidade': 'S\u00e3o Paulo',
          'bairro': 'Vila Ol\u00edmpia',
          'logradouro': 'Avenida dos Bandeirantes',
          'numero': null,
          'complemento': null,
          'cep': '04553-002',
          'numero_whatsapp': '11913675822',
          'numero_comercial': '1150269316',
          'numero_telefone_privado': '55404088844',
          'email_comercial': 'teste@teste.com',
          'email_privado': 'teste@teste.com'
        },
        {
          'id': 4,
          'icarros_id': null,
          'situacao_local_id': 3,
          'grupo_local_id': null,
          'nome': 'Av. Marq. S. Vicente, 182',
          'pai_local_id': null,
          'razao_social': 'NetCarros - Barra Funda',
          'cnpj': '34.350.715/0001-96',
          'arquivo_cnpj': '/netcarros/G6g8JtblRL2vuVi6-1599679002_original.pdf',
          'logomarca': '/netcarros/nAVx1cj73vTVCIWd-1610645634.png',
          'arquivo_cnpj_url_completa': 'https://netcarros.nyc3.digitaloceanspaces.com/netcarros/G6g8JtblRL2vuVi6-1599679002_original.pdf',
          'logomarca_url_completa': 'https://netcarros.nyc3.digitaloceanspaces.com/netcarros/nAVx1cj73vTVCIWd-1610645634.png',
          'uri': null,
          'estado': 'SP',
          'cidade': 'S\u00e3o Paulo',
          'bairro': 'V\u00e1rzea da Barra Funda',
          'logradouro': 'Avenida Marqu\u00eas de S\u00e3o Vicente',
          'numero': null,
          'complemento': null,
          'cep': '01139-000',
          'numero_whatsapp': '11913675822',
          'numero_comercial': '1150267656',
          'numero_telefone_privado': '11404088881',
          'email_comercial': 'teste@teste.com',
          'email_privado': 'teste@teste.com'
        }
      ],
      carrocerias: [
        {
          id: 1,
          categoria_id: 9,
          nome: 'Sedã',
          uri: 'seda',
          foto: null,
          foto_url_completa: null,
          prioridade: 1,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:53',
          updated_at: '2020-05-18 13:25:53',
          categoria: {
            id: 9,
            nome: 'Automovel',
            uri: 'automovel',
            foto: null,
            foto_url_completa: null,
            prioridade: 1,
            ativo: 0,
            grupo_categoria_id: null,
            termo_carroceria: null,
            created_at: '2020-04-23 16:28:22',
            updated_at: '2020-07-25 01:32:52'
          }
        },
        {
          id: 27,
          categoria_id: 9,
          nome: 'Sedan',
          uri: 'sedan',
          foto: null,
          foto_url_completa: null,
          prioridade: 1,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:53',
          updated_at: '2020-05-18 13:25:53',
          categoria: {
            id: 9,
            nome: 'Automovel',
            uri: 'automovel',
            foto: null,
            foto_url_completa: null,
            prioridade: 1,
            ativo: 0,
            grupo_categoria_id: null,
            termo_carroceria: null,
            created_at: '2020-04-23 16:28:22',
            updated_at: '2020-07-25 01:32:52'
          }
        },
        {
          id: 28,
          categoria_id: 9,
          nome: 'SUV',
          uri: 'suv',
          foto: null,
          foto_url_completa: null,
          prioridade: 1,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:53',
          updated_at: '2020-05-18 13:25:53',
          categoria: {
            id: 9,
            nome: 'Automovel',
            uri: 'automovel',
            foto: null,
            foto_url_completa: null,
            prioridade: 1,
            ativo: 0,
            grupo_categoria_id: null,
            termo_carroceria: null,
            created_at: '2020-04-23 16:28:22',
            updated_at: '2020-07-25 01:32:52'
          }
        },
        {
          id: 29,
          categoria_id: 9,
          nome: 'Picape',
          uri: 'picape',
          foto: null,
          foto_url_completa: null,
          prioridade: 1,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:53',
          updated_at: '2020-05-18 13:25:53',
          categoria: {
            id: 9,
            nome: 'Automovel',
            uri: 'automovel',
            foto: null,
            foto_url_completa: null,
            prioridade: 1,
            ativo: 0,
            grupo_categoria_id: null,
            termo_carroceria: null,
            created_at: '2020-04-23 16:28:22',
            updated_at: '2020-07-25 01:32:52'
          }
        },
        {
          id: 31,
          categoria_id: 9,
          nome: 'Utilitário',
          uri: 'utilitario',
          foto: null,
          foto_url_completa: null,
          prioridade: 1,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:53',
          updated_at: '2020-05-18 13:25:53',
          categoria: {
            id: 9,
            nome: 'Automovel',
            uri: 'automovel',
            foto: null,
            foto_url_completa: null,
            prioridade: 1,
            ativo: 0,
            grupo_categoria_id: null,
            termo_carroceria: null,
            created_at: '2020-04-23 16:28:22',
            updated_at: '2020-07-25 01:32:52'
          }
        },
        {
          id: 32,
          categoria_id: 9,
          nome: 'Conversível',
          uri: 'conversivel',
          foto: null,
          foto_url_completa: null,
          prioridade: 1,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:53',
          updated_at: '2020-05-18 13:25:53',
          categoria: {
            id: 9,
            nome: 'Automovel',
            uri: 'automovel',
            foto: null,
            foto_url_completa: null,
            prioridade: 1,
            ativo: 0,
            grupo_categoria_id: null,
            termo_carroceria: null,
            created_at: '2020-04-23 16:28:22',
            updated_at: '2020-07-25 01:32:52'
          }
        },
        {
          id: 33,
          categoria_id: 9,
          nome: 'Esportivo',
          uri: 'esportivo',
          foto: null,
          foto_url_completa: null,
          prioridade: 1,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:53',
          updated_at: '2020-05-18 13:25:53',
          categoria: {
            id: 9,
            nome: 'Automovel',
            uri: 'automovel',
            foto: null,
            foto_url_completa: null,
            prioridade: 1,
            ativo: 0,
            grupo_categoria_id: null,
            termo_carroceria: null,
            created_at: '2020-04-23 16:28:22',
            updated_at: '2020-07-25 01:32:52'
          }
        },
        {
          id: 2,
          categoria_id: 9,
          nome: 'Hatch',
          uri: 'hatch',
          foto: null,
          foto_url_completa: null,
          prioridade: 2,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:53',
          updated_at: '2020-05-18 13:25:53',
          categoria: {
            id: 9,
            nome: 'Automovel',
            uri: 'automovel',
            foto: null,
            foto_url_completa: null,
            prioridade: 1,
            ativo: 0,
            grupo_categoria_id: null,
            termo_carroceria: null,
            created_at: '2020-04-23 16:28:22',
            updated_at: '2020-07-25 01:32:52'
          }
        },
        {
          id: 3,
          categoria_id: 9,
          nome: 'Conversível/Cupê',
          uri: 'conversivelcupe',
          foto: null,
          foto_url_completa: null,
          prioridade: 3,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:53',
          updated_at: '2020-05-18 13:25:53',
          categoria: {
            id: 9,
            nome: 'Automovel',
            uri: 'automovel',
            foto: null,
            foto_url_completa: null,
            prioridade: 1,
            ativo: 0,
            grupo_categoria_id: null,
            termo_carroceria: null,
            created_at: '2020-04-23 16:28:22',
            updated_at: '2020-07-25 01:32:52'
          }
        },
        {
          id: 4,
          categoria_id: 9,
          nome: 'Van',
          uri: 'van',
          foto: null,
          foto_url_completa: null,
          prioridade: 4,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:53',
          updated_at: '2020-05-18 13:25:53',
          categoria: {
            id: 9,
            nome: 'Automovel',
            uri: 'automovel',
            foto: null,
            foto_url_completa: null,
            prioridade: 1,
            ativo: 0,
            grupo_categoria_id: null,
            termo_carroceria: null,
            created_at: '2020-04-23 16:28:22',
            updated_at: '2020-07-25 01:32:52'
          }
        },
        {
          id: 5,
          categoria_id: 9,
          nome: 'Wagon/Perua',
          uri: 'wagonperua',
          foto: null,
          foto_url_completa: null,
          prioridade: 5,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:53',
          updated_at: '2020-05-18 13:25:53',
          categoria: {
            id: 9,
            nome: 'Automovel',
            uri: 'automovel',
            foto: null,
            foto_url_completa: null,
            prioridade: 1,
            ativo: 0,
            grupo_categoria_id: null,
            termo_carroceria: null,
            created_at: '2020-04-23 16:28:22',
            updated_at: '2020-07-25 01:32:52'
          }
        },
        {
          id: 6,
          categoria_id: 9,
          nome: 'Utilitário Esportivo',
          uri: 'utilitario-esportivo',
          foto: null,
          foto_url_completa: null,
          prioridade: 6,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:53',
          updated_at: '2020-05-18 13:25:53',
          categoria: {
            id: 9,
            nome: 'Automovel',
            uri: 'automovel',
            foto: null,
            foto_url_completa: null,
            prioridade: 1,
            ativo: 0,
            grupo_categoria_id: null,
            termo_carroceria: null,
            created_at: '2020-04-23 16:28:22',
            updated_at: '2020-07-25 01:32:52'
          }
        },
        {
          id: 7,
          categoria_id: 9,
          nome: 'Picapes',
          uri: 'picapes',
          foto: null,
          foto_url_completa: null,
          prioridade: 7,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:53',
          updated_at: '2020-05-18 13:25:53',
          categoria: {
            id: 9,
            nome: 'Automovel',
            uri: 'automovel',
            foto: null,
            foto_url_completa: null,
            prioridade: 1,
            ativo: 0,
            grupo_categoria_id: null,
            termo_carroceria: null,
            created_at: '2020-04-23 16:28:22',
            updated_at: '2020-07-25 01:32:52'
          }
        },
        {
          id: 8,
          categoria_id: 9,
          nome: 'Monovolume',
          uri: 'monovolume',
          foto: null,
          foto_url_completa: null,
          prioridade: 8,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:53',
          updated_at: '2020-05-18 13:25:53',
          categoria: {
            id: 9,
            nome: 'Automovel',
            uri: 'automovel',
            foto: null,
            foto_url_completa: null,
            prioridade: 1,
            ativo: 0,
            grupo_categoria_id: null,
            termo_carroceria: null,
            created_at: '2020-04-23 16:28:22',
            updated_at: '2020-07-25 01:32:52'
          }
        },
        {
          id: 11,
          categoria_id: 9,
          nome: 'Semi pesado',
          uri: 'semi-pesado',
          foto: null,
          foto_url_completa: null,
          prioridade: 1,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-06-15 06:35:14',
          updated_at: '2020-06-15 06:35:14',
          categoria: {
            id: 9,
            nome: 'Automovel',
            uri: 'automovel',
            foto: null,
            foto_url_completa: null,
            prioridade: 1,
            ativo: 0,
            grupo_categoria_id: null,
            termo_carroceria: null,
            created_at: '2020-04-23 16:28:22',
            updated_at: '2020-07-25 01:32:52'
          }
        },
        {
          id: 12,
          categoria_id: 9,
          nome: 'Pesado',
          uri: 'pesado',
          foto: null,
          foto_url_completa: null,
          prioridade: 1,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-06-30 15:18:00',
          updated_at: '2020-06-30 15:18:00',
          categoria: {
            id: 9,
            nome: 'Automovel',
            uri: 'automovel',
            foto: null,
            foto_url_completa: null,
            prioridade: 1,
            ativo: 0,
            grupo_categoria_id: null,
            termo_carroceria: null,
            created_at: '2020-04-23 16:28:22',
            updated_at: '2020-07-25 01:32:52'
          }
        },
        {
          id: 14,
          categoria_id: 9,
          nome: 'Scooter/Cub',
          uri: 'scootercub',
          foto: null,
          foto_url_completa: null,
          prioridade: 1,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-06-12 08:42:46',
          updated_at: '2020-06-12 08:42:46',
          categoria: {
            id: 9,
            nome: 'Automovel',
            uri: 'automovel',
            foto: null,
            foto_url_completa: null,
            prioridade: 1,
            ativo: 0,
            grupo_categoria_id: null,
            termo_carroceria: null,
            created_at: '2020-04-23 16:28:22',
            updated_at: '2020-07-25 01:32:52'
          }
        },
        {
          id: 17,
          categoria_id: 9,
          nome: 'Trail',
          uri: 'trail',
          foto: null,
          foto_url_completa: null,
          prioridade: 1,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-06-24 14:49:26',
          updated_at: '2020-06-24 14:49:26',
          categoria: {
            id: 9,
            nome: 'Automovel',
            uri: 'automovel',
            foto: null,
            foto_url_completa: null,
            prioridade: 1,
            ativo: 0,
            grupo_categoria_id: null,
            termo_carroceria: null,
            created_at: '2020-04-23 16:28:22',
            updated_at: '2020-07-25 01:32:52'
          }
        },
        {
          id: 21,
          categoria_id: 9,
          nome: 'Street',
          uri: 'street',
          foto: null,
          foto_url_completa: null,
          prioridade: 1,
          ativo: 0,
          aprovado: 0,
          created_at: '2020-06-12 08:42:53',
          updated_at: '2020-06-24 13:46:29',
          categoria: {
            id: 9,
            nome: 'Automovel',
            uri: 'automovel',
            foto: null,
            foto_url_completa: null,
            prioridade: 1,
            ativo: 0,
            grupo_categoria_id: null,
            termo_carroceria: null,
            created_at: '2020-04-23 16:28:22',
            updated_at: '2020-07-25 01:32:52'
          }
        },
        {
          id: 24,
          categoria_id: 9,
          nome: 'Hibrido/Elétrico',
          uri: 'hibridoeletrico',
          foto: null,
          foto_url_completa: null,
          prioridade: 9,
          ativo: 0,
          aprovado: 1,
          created_at: '2020-05-18 13:25:53',
          updated_at: '2020-05-18 13:25:53',
          categoria: {
            id: 9,
            nome: 'Automovel',
            uri: 'automovel',
            foto: null,
            foto_url_completa: null,
            prioridade: 1,
            ativo: 0,
            grupo_categoria_id: null,
            termo_carroceria: null,
            created_at: '2020-04-23 16:28:22',
            updated_at: '2020-07-25 01:32:52'
          }
        }
      ],
      opcionaisDisplay: [],
      paginaAtualOpcionais: 0,
      fimPaginacaoOpcionais: 0,

      cores: [
        {
          id: 1,
          nome: 'Amarelo',
          uri: 'amarelo',
          foto: null,
          foto_url_completa: null,
          prioridade: 1,
          ativo: 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        },
        {
          id: 4,
          nome: 'Bege',
          uri: 'bege',
          foto: null,
          foto_url_completa: null,
          prioridade: 2,
          ativo: 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        },
        {
          id: 5,
          nome: 'Branco',
          uri: 'branco',
          foto: null,
          foto_url_completa: null,
          prioridade: 3,
          ativo: 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        },
        {
          id: 7,
          nome: 'Prata',
          uri: 'prata',
          foto: null,
          foto_url_completa: null,
          prioridade: 4,
          ativo: 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        },
        {
          id: 8,
          nome: 'Preto',
          uri: 'preto',
          foto: null,
          foto_url_completa: null,
          prioridade: 5,
          ativo: 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        },
        {
          id: 9,
          nome: 'Verde',
          uri: 'verde',
          foto: null,
          foto_url_completa: null,
          prioridade: 6,
          ativo: 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        },
        {
          id: 10,
          nome: 'Vermelho',
          uri: 'vermelho',
          foto: null,
          foto_url_completa: null,
          prioridade: 7,
          ativo: 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        },
        {
          id: 11,
          nome: 'Azul',
          uri: 'azul',
          foto: null,
          foto_url_completa: null,
          prioridade: 8,
          ativo: 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        },
        {
          id: 12,
          nome: 'Cinza',
          uri: 'cinza',
          foto: null,
          foto_url_completa: null,
          prioridade: 9,
          ativo: 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        },
        {
          id: 13,
          nome: 'Dourado',
          uri: 'dourado',
          foto: null,
          foto_url_completa: null,
          prioridade: 10,
          ativo: 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        },
        {
          id: 14,
          nome: 'Marrom',
          uri: 'marrom',
          foto: null,
          foto_url_completa: null,
          prioridade: 11,
          ativo: 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        },
        {
          id: 15,
          nome: 'Vinho',
          uri: 'vinho',
          foto: null,
          foto_url_completa: null,
          prioridade: 12,
          ativo: 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        },
        {
          id: 16,
          nome: 'Roxo',
          uri: 'roxo',
          foto: null,
          foto_url_completa: null,
          prioridade: 13,
          ativo: 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        },
        {
          id: 17,
          nome: 'Laranja',
          uri: 'laranja',
          foto: null,
          foto_url_completa: null,
          prioridade: 14,
          ativo: 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        },
        {
          id: 23,
          nome: 'Várias cores',
          uri: 'varias-cores',
          foto: null,
          foto_url_completa: null,
          prioridade: 15,
          ativo: 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        },
        {
          id: 24,
          nome: 'Não informada.',
          uri: 'nao-informada',
          foto: null,
          foto_url_completa: null,
          prioridade: 16,
          ativo: 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        },
        {
          id: 25,
          nome: 'Rosa',
          uri: 'rosa',
          foto: null,
          foto_url_completa: null,
          prioridade: 17,
          ativo: 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        },
        {
          id: 26,
          nome: 'Bronze',
          uri: 'bronze',
          foto: null,
          foto_url_completa: null,
          prioridade: 18,
          ativo: 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        }
      ],
      coresDisplay: [],
      paginaAtualCores: 0,
      fimPaginacaoCores: 0,
      finaisPlacas: [
        // {
        //   nome: '1 e 2',
        //   value: '1_2',
        //   selecionado: 0
        // },
        // {
        //   nome: '3 e 4',
        //   value: '3_4',
        //   selecionado: 0
        // },
        // {
        //   nome: '5 e 6',
        //   value: '5_6',
        //   selecionado: 0
        // },
        // {
        //   nome: '7 e 8',
        //   value: '7_8',
        //   selecionado: 0
        // },
        // {
        //   nome: '9 e 0',
        //   value: '9_0',
        //   selecionado: 0
        // }
      ],
      combustiveis: [
        {
          id: 2,
          nome: 'Gasolina',
          uri: 'gasolina',
          foto: null,
          foto_url_completa: null,
          prioridade: 1,
          ativo: 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        },
        {
          id: 3,
          nome: 'Álcool',
          uri: 'alcool',
          foto: null,
          foto_url_completa: null,
          prioridade: 2,
          ativo: 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        },
        {
          id: 4,
          nome: 'GNV',
          uri: 'gnv',
          foto: null,
          foto_url_completa: null,
          prioridade: 3,
          ativo: 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        },
        {
          id: 5,
          nome: 'Flex',
          uri: 'flex',
          foto: null,
          foto_url_completa: null,
          prioridade: 4,
          ativo: 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        },
        {
          id: 6,
          nome: 'Diesel',
          uri: 'diesel',
          foto: null,
          foto_url_completa: null,
          prioridade: 5,
          ativo: 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        },
        {
          id: 7,
          nome: 'MultiFlex (Alc, Gas, GNV)',
          uri: 'multiflex-alc-gas-gnv',
          foto: null,
          foto_url_completa: null,
          prioridade: 6,
          ativo: 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        },
        {
          id: 8,
          nome: 'Híbrido / Elétrico',
          uri: 'hibrido-eletrico',
          foto: null,
          foto_url_completa: null,
          prioridade: 7,
          ativo: 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        }
      ],

      retomadas: [
        {
          nome: 'Não Informada',
          ativo: 0
        },
        {
          nome: 'Recuperado Financiamento',
          ativo: 0
        },
        {
          nome: 'Recuperado Sinistro',
          ativo: 0
        }
      ],

      comitentes: [
        {
          nome: 'Banco GMAC S/A',
          ativo: 0
        },
        {
          nome: 'Banco Itau S/A',
          ativo: 0
        },
        {
          nome: 'Banco Santander S/A',
          ativo: 0
        },
        {
          nome: 'BV Financeira S/A',
          ativo: 0
        }
      ],
      opcaoFiltro: { nome: 'created_at', tipo: 'asc', label: 'recentes' },
      ordenarOpcoes: [
        { nome: 'created_at', tipo: 'desc', label: 'recentes' },
        { nome: 'valor_venda_pf', tipo: 'asc', label: 'menor valor' },
        { nome: 'valor_venda_pf', tipo: 'desc', label: 'maior valor' },
        {
          nome: 'marca_objeto.marca_nome.keyword',
          tipo: 'asc',
          label: 'marca'
        },
        {
          nome: 'modelo_objeto.modelo_nome.keyword',
          tipo: 'asc',
          label: 'modelo'
        },
        { nome: 'km', tipo: 'asc', label: 'menor km' },
        { nome: 'valor_fipe', tipo: 'asc', label: 'valor tabela' }
      ],
      tipoGrid: 'grid',

      verTodos: {
        tituloVoltar: '',
        campo: ''
      },

      buscaChips: [
        { nome: 'AUDI', ativo: true },
        { nome: 'A1', ativo: true }
      ],
      modoBusca: false,
      modoSelecaoMarca: false,
      quantidadeVeiculos: 15,
      pagina: 1,
      zero_km_true: false,
      zero_km_false: false,
      outlet_true: false,
      outlet_false: false,
      ipvapago_true: false,
      ipvapago_false: false,
      aplicativo_true: false,
      aplicativo_false: false,
      blackfriday_true: false,
      blackfriday_false: false,
      cyberweek_true: false,
      cyberweek_false: false,
      blindado_true: false,
      blindado_false: false,
      carregandoCards: true,
      tipoCard: 'vertical',
      anuncios: [],
      carroPCD: 0,
      pessoaJuridica: 0,
      visualizarParcela: 0
    } // fim data()
  },
  watch: {
    pagina (pagina, antes) {
      if (pagina !== antes && pagina !== 1) {
        this.paginar(pagina)
        this.buscar()
      }
    },
    busca_geral: {
      handler: debounce(function () {
        if ((this.dataInicializacao + 5000) < Date.now()) {
          this.adicionarBusca('geral', 'busca_geral', 'Busca: ' + this.busca_geral, this.busca_geral)
        }
      }, 1000),
      deep: true },
    precoToggle: {
      handler: debounce(function () {
        if (this.precoToggle === 1) {
        //
        } else {
          this.preco_inicial = this.valor_parcela_inicial
        }
      }, 100),
      deep: true },
    opcaoFiltro: {
      handler: debounce(function () {
        this.ordenar({ nome: this.opcaoFiltro.nome, tipo: this.opcaoFiltro.tipo })
      }, 100),
      deep: true },
    precoInput: {
      handler: debounce(function () {
        if (!this.alterandoPreco && ((this.dataInicializacao + 5000) < Date.now()) && this.precoInput.min && this.precoInput.max) {
          this.alterandoPreco = true
          this.preco = { min: parseFloat(this.precoInput.min), max: parseFloat(this.precoInput.max) }
          let label = 'Faixa de Preço: R$ '
          let termo = 'valor_venda_pf'
          if (this.precoToggle === 2) {
            label = 'Faixa de Preço da Parcela: R$ '
            termo = 'valor_parcelas'
          } else if (this.pcd) {
            termo = 'valor_venda_pcd'
          } else if (this.pj) {
            termo = 'valor_venda_pj'
          }
          this.adicionarBusca('range', termo, label + this.preco.min.toLocaleString() + ' até R$ ' + this.preco.max.toLocaleString(), this.preco)
          setTimeout(() => {
            this.alterandoPreco = false
          }, 3000)
        }
      }, 1000),
      deep: true },
    kmInput: {
      handler: debounce(function () {
        if (!this.alterandoKm && ((this.dataInicializacao + 5000) < Date.now()) && this.kmInput.min && this.kmInput.max) {
          this.alterandoKm = true
          this.km = { min: parseFloat(this.kmInput.min), max: parseFloat(this.kmInput.max) }
          this.adicionarBusca('range', 'km', 'KM mínimo: ' + this.km.min.toLocaleString() + ' km', this.km)
          setTimeout(() => {
            this.alterandoKm = false
          }, 3000)
        }
      }, 1000),
      deep: true },
    preco: {
      handler: debounce(function () {
        if (!this.alterandoPreco && (this.dataInicializacao + 5000) < Date.now()) {
          let label = 'Faixa de Preço: R$ '
          let termo = 'valor_venda_pf'
          if (this.precoToggle === 2) {
            label = 'Faixa de Preço da Parcela: R$ '
            termo = 'valor_parcelas'
          } else if (this.pcd) {
            termo = 'valor_venda_pcd'
          } else if (this.pj) {
            termo = 'valor_venda_pj'
          }
          this.alterandoPreco = true
          this.precoInput = { min: this.preco.min.toString(), max: this.preco.max.toString() }
          this.adicionarBusca('range', termo, label + this.preco.min.toLocaleString() + ' até R$ ' + this.preco.max.toLocaleString(), this.preco)
          setTimeout(() => {
            this.alterandoPreco = false
          }, 3000)
        }
      }, 1000),
      deep: true },
    ano: {
      handler: debounce(function () {
        if ((this.dataInicializacao + 5000) < Date.now()) {
          this.adicionarBusca('range', 'ano_modelo', 'Ano do Modelo: ' + this.ano.min + ' a ' + this.ano.max, this.ano)
        }
      }, 1000),
      deep: true },
    km: {
      handler: debounce(function () {
        if (!this.alterandoKm && (this.dataInicializacao + 5000) < Date.now()) {
          this.alterandoKm = true
          this.kmInput = { min: this.km.min.toString(), max: this.km.max.toString() }
          this.adicionarBusca('range', 'km', 'KM mínimo: ' + this.km.min.toLocaleString() + ' km', this.km)
          setTimeout(() => {
            this.alterandoKm = false
          }, 3000)
        }
      }, 1000),
      deep: true },
    '$q.screen.width' (valor) {
      if (valor < 599) {
        this.tipoCard = 'mobile'
      } else {
        this.tipoCard = 'vertical'
      }
    },
    buscaLabels: {
      handler: function (labels) {
        this.showCleanFilterButton = labels.length > 0
      },
      immediate: true
    }
  },
  computed: {
    buscaLabels () {
      const labels = this.requisicoesBusca.map((item) => {
        if (item.label.match(':')) {
          return item.label.split(':')[1]
        } else {
          return item.label
        }
      })
      console.log([labels])
      return labels
    },
    classeBtnToggle () {
      let classe = 'btn-toggle-lista'
      if (this.barraFiltros) {
        classe += ' ativo '
      }
      return classe
    },
    marcasComputed () {
      return this.$q.screen.lt.md ? clone(this.marcas).slice(0, 8) : this.marcas
    }
  },
  created () {
    if (this.$q.screen.gt.xs) {
      this.barraFiltros = false
    }
  },
  beforeRouteLeave (to, from, next) {
    if (process.env.MODE !== 'ssr' || process.env.CLIENT) {
      document.querySelector('.botoes a').href = 'https://api.whatsapp.com/send?phone=551151980247&text=Olá%20gostaria%20de%20saber%20com%20quais%20carros%20vocês%20trabalham?'
    }
    next()
  },
  async mounted () {
    this.linkpage = 'https://api.whatsapp.com/send?phone=551151980247&text=Olá%20Gostei%20das%20opções,%20mas%20ainda%20nao%20defini%20um%20carro.%20Poderia%20me%20ajudar?'
    document.querySelector('.botoes a').href = this.linkpage
    this.intervalMarcaModelo = setInterval(() => {
      this.titulo = (this.marcasSelecionadas.filter((item) => item && item.nome).length ? this.marcasSelecionadas.filter((item) => item && item.nome).map((item, index) => item.nome + ((this.modelosSelecionados[index] ? ' ' + this.modelosSelecionados[index].nome : ''))).join(', ') + ' carros' : 'Carros') + ' usados e seminovos'
      const selectedLocal = this.locais.filter((item) => item.selecionado === 1)
      if (selectedLocal.length > 0) {
        console.log(selectedLocal)
        this.titulo += ' em ' + selectedLocal[0].chave_interna
      }
    }, 500)

    EventBus.$on('busca_geral', (buscaGeral) => {
      this.adicionarBusca('geral', 'busca_geral', 'Busca: ' + buscaGeral, buscaGeral)
    })
    EventBus.$on('faixa_preco', (faixaPreco) => {
      this.preco.min = parseInt(faixaPreco.min)
      this.preco.max = parseInt(faixaPreco.max)
    })
    EventBus.$on('busca_marca_modelo', (buscaMarcaModelo) => {
      if (!this.marcasSelecionadas[this.marcasSelecionadas.length - 1].nome) {
        const marca = this.marcasSelecionadas[this.marcasSelecionadas.length - 1]
        marca.nome = buscaMarcaModelo.marca
        marca.chave_interna = buscaMarcaModelo.marca.toLowerCase()
      } else {
        const marca = {
          nome: buscaMarcaModelo.marca,
          chave_interna: buscaMarcaModelo.marca.toLowerCase(),
          ativo: true,
          selecionado: 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        }
        this.marcasSelecionadas.push(marca)
      }
      this.alterarMarca(
        this.marcasSelecionadas[this.marcasSelecionadas.length - 1],
        'multiplo',
        'marca_objeto.marca_nome.keyword',
        'Marca: ' + buscaMarcaModelo.marca,
        buscaMarcaModelo.marca,
        1,
        true,
        this.marcasSelecionadas.length - 1
      )

      const modelo = {
        nome: buscaMarcaModelo.modelo,
        chave_interna: buscaMarcaModelo.modelo.toLowerCase(),
        marca: buscaMarcaModelo.marca,
        ativo: true,
        selecionado: 0,
        created_at: '2020-05-18 13:25:32',
        updated_at: '2020-05-18 13:25:32'
      }
      this.modelosSelecionados.push(modelo)
      this.alterarModelo(
        this.modelosSelecionados[this.modelosSelecionados.length - 1],
        'multiplo',
        'modelo_objeto.modelo_nome.keyword',
        'Modelo: ' + buscaMarcaModelo.modelo,
        buscaMarcaModelo.modelo,
        1,
        true,
        this.modelosSelecionados.length - 1
      )
    })
    this.atualizarQtdPaginas()
    // this.verMaisMarcas()
    // this.verMaisOpcionais()
    // this.verMaisCores()
    this.elasticSearchModel = new ElasticSearchModel()
    await this.buscarPossiveis()
    if (this.ordenarOpcoes[0].nome === 'created_at') {
      this.elasticSearchModel.busca.sort = [{ situacao_id: 'asc' }, { [this.ordenarOpcoes[0].nome]: this.ordenarOpcoes[0].tipo }]
    } else {
      this.elasticSearchModel.busca.sort = [{ [this.ordenarOpcoes[0].nome]: this.ordenarOpcoes[0].tipo }]
    }
    this.inicializar()
    await this.buscar(true)
    setTimeout(() => {
      this.carregandoCards = false
    }, 800)
    if (this.$q.screen.lt.md) {
      this.barraFiltros = false
      if (this.$q.screen.lt.sm) {
        this.tipoCard = 'mobile'
      } else {
        this.tipoCard = 'vertical'
      }
    } else {
      this.barraFiltros = false
      this.tipoCard = 'vertical'
    }
    const masonry = document.querySelector('#infinite-list')
    window.onscroll = (e) => {
      // masonry.addEventListener('scroll', e => {
      if (!this.carregandoBusca) {
        if (window.scrollY >= (masonry.scrollTop + masonry.clientHeight - 470)) {
          if (this.pagina < Math.ceil(this.quantidade / this.qtdPorPagina)) {
            // console.log('mansonry.scrollTop: ' + masonry.scrollTop)
            // console.log('masonry.clientHeight: ' + masonry.clientHeight)
            // console.log('total: ' + (masonry.scrollTop + masonry.clientHeight))
            // console.log('window.scrollY: ' + window.scrollY)
            this.pagina++
            // this.buscar()
          }
        }
      }
    }
  },
  methods: {
    scrolltoTop () {
      let element = document.getElementById('q-app')
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
      // document.getElementById('q-app').scrollTop = 0
    },
    scrollFilterToTop () {
      let element = document.getElementById('barra-filtros')
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
      // document.getElementById('q-app').scrollTop = 0
    },
    modelosComputed () {
      const modelosSelecionados = []
      this.marcasSelecionadas.forEach((value, indice) => {
        modelosSelecionados.push(this.modelos.filter((item) => item.marca === this.marcasSelecionadas[indice].nome))
        if (indice === modelosSelecionados.length) {
          modelosSelecionados.push([])
        }
      })
      return modelosSelecionados
    },
    versoesComputed () {
      const versoesSelecionadas = []
      this.marcasSelecionadas.forEach((value, indice) => {
        value = this.modelosSelecionados[indice]
        versoesSelecionadas.push(this.versoes.filter((item) => item.modelo === (value ? value.nome : false)))
        if (indice === versoesSelecionadas.length) {
          versoesSelecionadas.push([])
        }
      })
      return versoesSelecionadas
    },
    inicializar () {
      let params = this.$route.query

      if (params.ordenacao) {
        if (params.ordenacao.split(' ')[0] === 'created_at') {
          this.elasticSearchModel.busca.sort = [{ situacao_id: 'asc' }, { [params.ordenacao.split(' ')[0]]: params.ordenacao.split(' ')[1] }]
        } else {
          this.elasticSearchModel.busca.sort = [{ [params.ordenacao.split(' ')[0]]: params.ordenacao.split(' ')[1] }]
        }
      }

      if (params.pagina) {
        this.pagina = parseInt(params.pagina)
      }

      if (params.busca_geral) {
        let labelBusca = ((params.busca_geral.indexOf('|') > -1) ? params.busca_geral.split('|')[0] : params.busca_geral)

        let valorBusca = ((params.busca_geral.indexOf('|') > -1) ? params.busca_geral.split('|')[1] : params.busca_geral)

        this.requisicoesBusca.push({ tipo: 'geral', termo: 'busca_geral', label: labelBusca, valor: valorBusca })
      }
      delete params.busca_geral
      Object.entries(params).filter((item) => item[1].indexOf('|') > -1).forEach((elemento) => {
        let chave = elemento[0]
        let label = elemento[1].split('|')[0]
        let valor = elemento[1].split('|')[1]
        if ((chave !== 'ordenacao') && (chave !== 'pagina') && (chave !== 'vendedor')) {
          valor = valor.replace('%20', '+')
          if (valor.substr(valor.length - 1) === ' ' || valor.substr(valor.length - 1) === '+') {
            valor.split(' ').forEach((elemento, indice) => {
              const valorTeste = valor.replace(/,,,/g, ' ').replace(/\.\.\./g, '-').trim()
              if (elemento) {
                this.requisicoesBusca.push({
                  tipo: 'multiplo',
                  termo: chave,
                  label: label.split('-')[indice].replace(/,,,/g, ' ').replace(/\.\.\./g, '-'),
                  valor: elemento.replace(/,,,/g, ' ').replace(/\.\.\./g, '-')
                })
                if (chave === 'marca_objeto.marca_nome.keyword') {
                  this.marcas = this.marcas.map((elemento) => {
                    if (valorTeste === elemento.nome.trim()) {
                      elemento.selecionado = 1
                    }
                    return elemento
                  })
                  if (this.marcasSelecionadas[0].nome === '') {
                    this.marcasSelecionadas = []
                  }
                  this.marcasSelecionadas.push({ nome: valorTeste, chave_interna: elemento.toLowerCase() })
                }
                if (chave === 'modelo_objeto.modelo_nome.keyword') {
                  this.modelos = this.modelos.map((elemento) => {
                    if (valorTeste === elemento.nome.trim()) {
                      elemento.selecionado = 1
                    }
                    return elemento
                  })
                  let modelosSelecionados = this.modelosSelecionados.map((item) => item)
                  modelosSelecionados.push({ nome: valorTeste, marca: this.requisicoesBusca.find((elemento) => elemento.termo === 'marca_objeto.marca_nome.keyword') })
                  this.modelosSelecionados = Array.from(modelosSelecionados)
                  console.log(this.modelosSelecionados)
                }
                if (chave === 'proprietario_local.razao_social.keyword') {
                  this.locais = this.locais.map((elemento) => {
                    if (valorTeste === elemento.chave_interna.trim()) {
                      elemento.selecionado = 1
                    }
                    return elemento
                  })
                }
                if (chave === 'zero_km') {
                  if (valorTeste === 'true') {
                    this.zero_km_true = true
                  } else {
                    this.zero_km_false = true
                  }
                }
                if (chave === 'outlet') {
                  if (valorTeste === 'true') {
                    this.outlet_true = true
                  } else {
                    this.outlet_false = true
                  }
                }
                if (chave === 'ipva_pago') {
                  if (valorTeste === 'true') {
                    this.ipvapago_true = true
                  } else {
                    this.ipvapago_false = true
                  }
                }
                if (chave === 'aplicativo') {
                  if (valorTeste === 'true') {
                    this.aplicativo_true = true
                  } else {
                    this.aplicativo_false = true
                  }
                }
                if (chave === 'blackfriday') {
                  if (valorTeste === 'true') {
                    this.blackfriday_true = true
                  } else {
                    this.blackfriday_false = true
                  }
                }
                if (chave === 'cyberweek') {
                  if (valorTeste === 'true') {
                    this.cyberweek_true = true
                  } else {
                    this.cyberweek_false = true
                  }
                }
                if (chave === 'blindado') {
                  if (valorTeste === 'true') {
                    this.blindado_true = true
                  } else {
                    this.blindado_false = true
                  }
                }

                if (chave === 'cambio_objeto.cambio_nome.keyword') {
                  this.cambios = this.cambios.map((elemento) => {
                    if (valorTeste === elemento.nome.trim()) {
                      elemento.selecionado = 1
                    }
                    return elemento
                  })
                }
                if (chave === 'cor_objeto.cor_nome.keyword') {
                  this.cores = this.cores.map((elemento) => {
                    if (valorTeste === elemento.nome.trim()) {
                      elemento.selecionado = 1
                    }
                    return elemento
                  })
                }
                if (chave === 'combustivel_objeto.combustivel_nome.keyword') {
                  this.combustiveis = this.combustiveis.map((elemento) => {
                    if (valorTeste === elemento.nome.trim()) {
                      elemento.selecionado = 1
                    }
                    return elemento
                  })
                }
                if (chave === 'estoque_local.razao_social.keyword') {
                  this.locais = this.locais.map((elemento) => {
                    if (valorTeste === elemento.razao_social.trim()) {
                      elemento.selecionado = 1
                    }
                    return elemento
                  })
                }
                if (chave === 'acessorios_busca_nome') {
                  this.opcionais = this.opcionais.map((elemento) => {
                    if (valorTeste === elemento.nome.trim()) {
                      elemento.selecionado = 1
                    }
                    return elemento
                  })
                }
                if (chave === 'placa_final.keyword') {
                  this.finaisPlacas = this.finaisPlacas.map((elemento) => {
                    if (valorTeste === elemento.value.trim()) {
                      elemento.selecionado = 1
                    }
                    return elemento
                  })
                }
                if (chave === 'carroceria_objeto.carroceria_nome.keyword') {
                  this.carrocerias = this.carrocerias.map((elemento) => {
                    if (valorTeste === elemento.nome.trim()) {
                      elemento.selecionado = 1
                    }
                    return elemento
                  })
                }
                if (chave === 'categoria_objeto.categoria_nome.keyword') {
                  this.categorias = this.categorias.map((elemento) => {
                    if (valorTeste === elemento.nome.trim()) {
                      elemento.selecionado = 1
                    }
                    return elemento
                  })
                }
                if (chave === 'tags_busca_nome.keyword') {
                  this.tags = this.tags.map((elemento) => {
                    if (valorTeste === elemento.nome.trim()) {
                      elemento.selecionado = 1
                    }
                    return elemento
                  })
                }
              }
            })
          } else if (valor.indexOf(',') > 0) {
            this.requisicoesBusca.push({ tipo: 'range', termo: chave, label: label, valor: { min: valor.split(',')[0].replace('.', ''), max: valor.split(',')[1].replace('.', '') } })
            if (chave === 'valor_venda') {
              this.preco = { min: valor.split(',')[0], max: valor.split(',')[1] }
            } else if (chave === 'valor_venda_pcd') {
              this.preco = { min: valor.split(',')[0], max: valor.split(',')[1] }
              this.pcd = 1
              this.valor_pcd_inicial = { min: valor.split(',')[0], max: valor.split(',')[1] }
            } else if (chave === 'valor_venda_pj') {
              this.preco = { min: valor.split(',')[0], max: valor.split(',')[1] }
              this.pj = 1
              this.valor_pj_inicial = { min: valor.split(',')[0], max: valor.split(',')[1] }
            } else if (chave === 'ano_modelo') {
              this.ano = { min: valor.split(',')[0], max: valor.split(',')[1] }
            } else if (chave === 'km') {
              this.km = { min: valor.split(',')[0], max: valor.split(',')[1] }
            }
          } else {
            if (chave === 'dados.VEI_TIPO.keyword') {
              this.categoria = valor
            }
            if (valor === 'true') {
              this[chave + 'Selecionado'] = true
            } else if (chave === 'cor_objeto.cor_id') {
              this.corSelecionada = parseInt(valor)
            } else {
              this[chave + 'Selecionado'] = valor
            }
            this.requisicoesBusca.push({ tipo: 'exata', termo: chave, label: label, valor: valor })
          }
        }
      })
    },
    scrollObserverLista (scroll) {
      // console.warn('to atualizando o scrollX', scroll.position)
      this.scrollX = scroll.position
      // if (this.$q.screen.width !== this.widthAtualizado || this.$q.screen.height !== this.heightAtualizado) {
      //   this.widthAtualizado = this.$q.screen.width
      //   this.heightAtualizado = this.$q.screen.height
      //   // console.warn('olha  o scrolll aeee', this.$q.screen.height)
      //   // console.warn('olha  o scrolll aeee', this.$q.screen.width)
      //   EventBus.$emit('atualizarQtdPorPagina')
      // }
    },
    atualizarQtdPaginas () {
      this.widthCard = 375
      this.heightCard = 380
      this.widthBarraBusca = 320
      this.heightTopo = 80
      if (this.$q.screen.width !== this.widthAtualizado) {
        this.widthAtualizado = (this.$q.screen.width)
        this.qtdPorLinha = Math.floor((this.widthAtualizado - this.widthBarraBusca) / this.widthCard)
        // console.warn(this.widthCard, 'widthCard')
        // console.warn(this.widthAtualizado, 'widthAtualizado')
        // console.warn(this.qtdPorLinha, 'qtdPorLinha')
      }
      if (this.$q.screen.height !== this.heightAtualizado) {
        this.heightAtualizado = this.$q.screen.height
        this.qtdLinhas = Math.floor((this.heightAtualizado - this.heightTopo) / this.heightCard)
        // console.warn(this.heightAtualizado, 'heightAtualizado')
        // console.warn(this.qtdLinhas, 'qtdLinhas')
      }
      if (this.qtdLinhas <= 2) {
        this.qtdLinhas = 3
      }
      this.qtdPorPagina = this.qtdLinhas * this.qtdPorLinha
      if (this.qtdPorPagina < 9) {
        this.qtdPorPagina = 29
      }
      if (this.widthAtualizado < 440) {
        this.qtdPorPagina = 29
      }
      // this.$store.commit('cidade-proxima/putCampoForm', { campo: 'qtdPorPagina', value: this.qtdPorPagina })
      // fim copiar e colar lá
    },
    paginar (pagina) {
      let url = new URL(window.location.href)
      let params = new URLSearchParams(url.search.slice(1))
      params.delete('pagina')

      params.append('pagina', pagina)
      window.history.replaceState({}, '', `${location.pathname}?${params}`)
    },
    async buscar (buscaInicial = false) {
      // let url = new URL(window.location.href)
      // let params = new URLSearchParams(url.search.slice(1))
      // if (buscaInicial && params.length && LocalStorage.has('retornoAnuncios')) {
      //   return this.popularAnuncios(LocalStorage.getItem('retornoAnuncios'))
      // }
      this.carregandoBusca = true
      let requisicoesBusca = this.requisicoesBusca.map((item) => item)
      let versaoRequiscaoBusca = this.requisicoesBusca.filter((item) => item.termo === 'versao_objeto.versao_nome.keyword')

      // eslint-disable-next-line no-unused-vars
      let buscaIncluir = versaoRequiscaoBusca.map((item) => {
        const versao = this.versoes.find((versao) => item.valor === versao.nome)
        requisicoesBusca = requisicoesBusca.filter(item => item.valor !== versao.nome).filter((item) => item.valor !== versao.modelo).filter((item) => item.valor !== versao.marca)
        return versao.marca + '|' + versao.modelo + '|' + versao.nome
      })
      let modeloRequiscaoBusca = requisicoesBusca.filter((item) => item.termo === 'modelo_objeto.modelo_nome.keyword')

      buscaIncluir = buscaIncluir.concat(modeloRequiscaoBusca.map((item) => {
        const modelo = this.modelos.find((modelo) => item.valor === modelo.nome)
        requisicoesBusca = requisicoesBusca.filter(item => item.valor !== modelo.nome).filter((item) => item.valor !== modelo.marca)
        return modelo.marca + '|' + modelo.nome
      }))

      let marcaRequiscaoBusca = requisicoesBusca.filter((item) => item.termo === 'marca_objeto.marca_nome.keyword')

      buscaIncluir = buscaIncluir.concat(marcaRequiscaoBusca.map((item) => {
        requisicoesBusca = requisicoesBusca.filter(itemInterno => item.valor !== itemInterno.valor)
        return item.valor
      }))

      requisicoesBusca = requisicoesBusca.concat(buscaIncluir.map((item) => {
        return {
          label: '',
          termo: 'marca_modelo_versao_busca.keyword',
          tipo: 'contendo',
          valor: item
        }
      }))

      return this.elasticSearchModel.buscar(requisicoesBusca.filter((elemento) => {
        let ignorarModelo = this.marcasSelecionadas.length !== this.modelosSelecionados.length
        let ignorarVersao = (this.marcasSelecionadas.length !== this.modelosSelecionados.length) || (this.modelosSelecionados.length !== this.versoesSelecionadas.length)
        if ((ignorarModelo && (elemento.termo === 'modelo_objeto.modelo_nome.keyword')) || (ignorarVersao && (elemento.termo === 'versao_objeto.versao_nome.keyword'))) {
          return false
        } else {
          return true
        }
      }), this.$store, this.pagina, this.qtdPorPagina).then((retorno) => {
        this.aggregations = retorno.aggregations
        this.popularAnuncios(retorno, buscaInicial)
        this.popularPossiveis(retorno)
        if (buscaInicial) {
          LocalStorage.set('retornoAnuncios', retorno)
        }
      })
    },
    async buscarPossiveis () {
      this.carregandoBusca = true
      // if (LocalStorage.has('retornoPossiveis')) {
      //   this.elasticSearchModel.possiveis().then((retorno) => {
      //     LocalStorage.set('retornoPossiveis', retorno)
      //   })
      //   return this.popularPossiveis(LocalStorage.getItem('retornoPossiveis'))
      // }
      return this.elasticSearchModel.possiveis().then((retorno) => {
        this.popularPossiveis(retorno)
        LocalStorage.set('retornoPossiveis', retorno)
      })
    },
    adicionarMarca () {
      this.marcasSelecionadas.push({
        nome: '',
        chave_interna: '',
        ativo: true,
        selecionado: 0,
        created_at: '2020-05-18 13:25:32',
        updated_at: '2020-05-18 13:25:32'
      })
      this.marcasAlteracao++
    },
    receberEventoMarca (indice) {
      this.marcasAlteracao++
      this.alterarMarca(
        this.marcasSelecionadas[indice],
        'multiplo',
        'marca_objeto.marca_nome.keyword',
        'Marca: ' + this.marcasSelecionadas[indice].nome,
        this.marcasSelecionadas[indice].nome,
        1,
        true,
        indice
      )
    },
    alterarMarca (marca, tipo, termo, label, valor, adicionar, modoSelecao, indice) {
      this.marcasAlteracao++
      this.modoSelecaoMarca = true
      if (adicionar) {
        if (indice === undefined) {
          this.marcasSelecionadas[0] = marca
        } else {
          this.marcasSelecionadas[indice] = marca
        }
      }
      this.marcas = this.marcas.map((elemento) => {
        if (marca.nome === elemento.nome) {
          if (elemento.selecionado) {
            elemento.selecionado = 1
          } else {
            elemento.selecionado = 0
          }
        }
        return elemento
      })
      this.alterarValorBusca(tipo, termo, label, valor, adicionar)
    },
    alterarModelo (modelo, tipo, termo, label, valor, adicionar, modoSelecao, indice) {
      for (let i = 0; i < this.modelosSelecionadosAnterior.length; i++) {
        if (this.modelosSelecionadosAnterior[i] && (this.modelosSelecionados[i] !== this.modelosSelecionadosAnterior[i])) {
          const indiceModelo = this.requisicoesBusca.findIndex((elemento) => elemento.valor === this.modelosSelecionadosAnterior[i].nome)
          this.removerBuscaPorIndice(indiceModelo)
        }
      }
      this.modelosSelecionadosAnterior[indice] = this.modelosSelecionados[indice]

      if (adicionar) {
        if (indice === undefined) {
          this.modelosSelecionados[0] = modelo
        } else {
          let modelosSelecionados = this.modelosSelecionados.map((item) => item)
          modelosSelecionados[indice] = modelo
          this.modelosSelecionados = modelosSelecionados
        }
      }
      this.alterarValorBusca(tipo, termo, label, valor, adicionar)
    },
    alterarVersao (versao, tipo, termo, label, valor, adicionar, modoSelecao, indice) {
      if (adicionar) {
        if (indice === undefined) {
          this.versoesSelecionadas[0] = versao
        } else {
          this.versoesSelecionadas[indice] = versao
        }
      }
      this.alterarValorBusca(tipo, termo, label, valor, adicionar)
    },
    alterarPCD (segmento, tipo, termo, label, valor, adicionar) {
      this.removerBuscaTodos()
      if (adicionar) {
        this.pj = 0
        this.preco_inicial = this.valor_pcd_inicial
        this.ordenarOpcoes = [
          { nome: 'created_at', tipo: 'desc', label: 'Recentes' },
          { nome: 'valor_venda_pcd', tipo: 'asc', label: 'Menor valor' },
          { nome: 'valor_venda_pcd', tipo: 'desc', label: 'Maior valor' },
          {
            nome: 'marca_objeto.marca_nome.keyword',
            tipo: 'asc',
            label: 'Marca'
          },
          {
            nome: 'modelo_objeto.modelo_nome.keyword',
            tipo: 'asc',
            label: 'Modelo'
          },
          { nome: 'km', tipo: 'asc', label: 'Menor km' },
          { nome: 'valor_fipe', tipo: 'asc', label: 'Valor tabela' }
        ]
        this.opcaoFiltro = { nome: 'valor_venda_pcd', tipo: 'asc', label: 'Menor valor' }

        if (this.opcaoFiltro.nome === 'created_at') {
          this.elasticSearchModel.busca.sort = [{ situacao_id: 'asc' }, { [this.opcaoFiltro.nome]: this.opcaoFiltro.tipo }]
        } else {
          this.elasticSearchModel.busca.sort = [{ [this.opcaoFiltro.nome]: this.opcaoFiltro.tipo }]
        }
        // this.elasticSearchModel.busca.sort = [{ valor_venda_pcd: 'asc' }]
        this.preco_inicial = this.valor_pf_inicial
      } else {
        this.alterarPf()
      }
      this.alterarValorBusca(tipo, termo, label, valor, adicionar)
    },
    alterarPJ (segmento, tipo, termo, label, valor, adicionar) {
      this.removerBuscaTodos()
      if (adicionar) {
        this.pcd = 0
        this.preco_inicial = this.valor_pj_inicial
        this.ordenarOpcoes = [
          { nome: 'created_at', tipo: 'desc', label: 'Recentes' },
          { nome: 'valor_venda_pj', tipo: 'asc', label: 'Menor valor' },
          { nome: 'valor_venda_pj', tipo: 'desc', label: 'Maior valor' },
          {
            nome: 'marca_objeto.marca_nome.keyword',
            tipo: 'asc',
            label: 'Marca'
          },
          {
            nome: 'modelo_objeto.modelo_nome.keyword',
            tipo: 'asc',
            label: 'Modelo'
          },
          { nome: 'km', tipo: 'asc', label: 'Menor km' },
          { nome: 'valor_fipe', tipo: 'asc', label: 'Valor tabela' }
        ]

        this.opcaoFiltro = { nome: 'valor_venda_pj', tipo: 'asc', label: 'Menor valor' }

        if (this.opcaoFiltro.nome === 'created_at') {
          this.elasticSearchModel.busca.sort = [{ situacao_id: 'asc' }, { [this.opcaoFiltro.nome]: this.opcaoFiltro.tipo }]
        } else {
          this.elasticSearchModel.busca.sort = [{ [this.opcaoFiltro.nome]: this.opcaoFiltro.tipo }]
        }

        // this.elasticSearchModel.busca.sort = [{ valor_venda_pj: 'asc' }]
        this.preco_inicial = this.valor_pj_inicial
      } else {
        this.alterarPf()
      }
      this.alterarValorBusca(tipo, termo, label, valor, adicionar)
    },
    alterarPf () {
      this.removerBuscaTodos()
      this.ordenarOpcoes = [
        { nome: 'created_at', tipo: 'desc', label: 'Recentes' },
        { nome: 'valor_venda_pf', tipo: 'asc', label: 'Menor valor' },
        { nome: 'valor_venda_pf', tipo: 'desc', label: 'Maior valor' },
        {
          nome: 'marca_objeto.marca_nome.keyword',
          tipo: 'asc',
          label: 'Marca'
        },
        {
          nome: 'modelo_objeto.modelo_nome.keyword',
          tipo: 'asc',
          label: 'Modelo'
        },
        { nome: 'km', tipo: 'asc', label: 'Menor km' },
        { nome: 'valor_fipe', tipo: 'asc', label: 'Valor tabela' }
      ]
      this.pcd = 0
      this.pj = 0
      this.preco_inicial = this.valor_pf_inicial
      this.opcaoFiltro = { nome: 'valor_venda_pf', tipo: 'asc', label: 'Menor valor' }
      if (this.opcaoFiltro.nome === 'created_at') {
        this.elasticSearchModel.busca.sort = [{ situacao_id: 'asc' }, { [this.opcaoFiltro.nome]: this.opcaoFiltro.tipo }]
      } else {
        this.elasticSearchModel.busca.sort = [{ [this.opcaoFiltro.nome]: this.opcaoFiltro.tipo }]
      }
      // this.elasticSearchModel.busca.sort = [{ valor_venda_pf: 'asc' }]
    },
    alterarZeroKm (segmento, tipo, termo, label, valor, adicionar) {
      this.alterarValorBusca(tipo, termo, label, valor, adicionar)
    },
    alterarOutlet (segmento, tipo, termo, label, valor, adicionar) {
      this.alterarValorBusca(tipo, termo, label, valor ? 1 : 0, adicionar)
    },
    alterarIpvaPago (segmento, tipo, termo, label, valor, adicionar) {
      this.alterarValorBusca(tipo, termo, label, valor ? 1 : 0, adicionar)
    },
    alterarAplicativo (segmento, tipo, termo, label, valor, adicionar) {
      this.alterarValorBusca(tipo, termo, label, valor ? 1 : 0, adicionar)
    },
    alterarBlackfriday (segmento, tipo, termo, label, valor, adicionar) {
      this.alterarValorBusca(tipo, termo, label, valor ? 1 : 0, adicionar)
    },
    alterarBlindado (segmento, tipo, termo, label, valor, adicionar) {
      this.alterarValorBusca(tipo, termo, label, valor, adicionar)
    },
    alterarCambio (segmento, tipo, termo, label, valor, adicionar) {
      this.cambios = this.cambios.map((elemento) => {
        if (segmento.nome === elemento.nome) {
          if (elemento.selecionado) {
            elemento.selecionado = 1
          } else {
            elemento.selecionado = 0
          }
        }
        return elemento
      })
      this.alterarValorBusca(tipo, termo, label, valor, adicionar)
    },
    alterarCor (segmento, tipo, termo, label, valor, adicionar) {
      this.cores = this.cores.map((elemento) => {
        if (segmento.nome === elemento.nome) {
          if (elemento.selecionado) {
            elemento.selecionado = 1
          } else {
            elemento.selecionado = 0
          }
        }
        return elemento
      })
      this.alterarValorBusca(tipo, termo, label, valor, adicionar)
    },
    alterarLocal (segmento, tipo, termo, label, valor, adicionar) {
      this.locais = this.locais.map((elemento) => {
        if (segmento.nome === elemento.nome) {
          if (elemento.selecionado) {
            elemento.selecionado = 1
          } else {
            elemento.selecionado = 0
          }
        }
        return elemento
      })
      this.alterarValorBusca(tipo, termo, label, valor, adicionar)
    },
    alterarCombustivel (segmento, tipo, termo, label, valor, adicionar) {
      this.combustiveis = this.combustiveis.map((elemento) => {
        if (segmento.nome === elemento.nome) {
          if (elemento.selecionado) {
            elemento.selecionado = 1
          } else {
            elemento.selecionado = 0
          }
        }
        return elemento
      })
      this.alterarValorBusca(tipo, termo, label, valor, adicionar)
    },
    alterarValorParcela (segmento, tipo, termo, label, adicionar) {
      if (adicionar) {
        this.faixa_parcela = this.faixa_parcela.map((elemento) => {
          if (segmento.chave_interna === elemento.chave_interna) {
            elemento.selecionado = 1
          } else {
            elemento.selecionado = 0
          }
          return elemento
        })
        this.adicionarBusca(tipo, termo, label + 'R$ ' + segmento.from.toLocaleString() + ' até R$ ' + segmento.to.toLocaleString(), { min: segmento.from, max: segmento.to })
      } else {
        this.removerBusca(tipo, termo, label + 'R$ ' + segmento.from.toLocaleString() + ' até R$ ' + segmento.to.toLocaleString(), { min: segmento.from, max: segmento.to })
      }
    },
    alterarFaixaKm (segmento, tipo, termo, label, adicionar) {
      if (adicionar) {
        this.faixa_km = this.faixa_km.map((elemento) => {
          if (segmento.chave_interna === elemento.chave_interna) {
            elemento.selecionado = 1
          } else {
            elemento.selecionado = 0
          }
          return elemento
        })
        this.adicionarBusca(tipo, termo, label + segmento.from.toLocaleString() + 'Km até ' + segmento.to.toLocaleString() + 'Km', { min: segmento.from, max: segmento.to })
      } else {
        this.removerBusca(tipo, termo, label + segmento.from.toLocaleString() + 'Km até ' + segmento.to.toLocaleString() + 'Km', { min: segmento.from, max: segmento.to })
      }
    },
    alterarValorVenda (segmento, tipo, termo, label, adicionar) {
      if (adicionar) {
        this.faixa_valor = this.faixa_valor.map((elemento) => {
          if (segmento.chave_interna === elemento.chave_interna) {
            elemento.selecionado = 1
          } else {
            elemento.selecionado = 0
          }
          return elemento
        })
        this.adicionarBusca(tipo, termo, label + 'R$ ' + segmento.from.toLocaleString() + ' até R$ ' + segmento.to.toLocaleString(), { min: segmento.from, max: segmento.to })
      } else {
        this.removerBusca(tipo, termo, label + 'R$ ' + segmento.from.toLocaleString() + ' até R$ ' + segmento.to.toLocaleString(), { min: segmento.from, max: segmento.to })
      }
    },
    alterarOpcional (segmento, tipo, termo, label, valor, adicionar) {
      this.combustiveis = this.combustiveis.map((elemento) => {
        if (segmento.nome === elemento.nome) {
          if (elemento.selecionado) {
            elemento.selecionado = 1
          } else {
            elemento.selecionado = 0
          }
        }
        return elemento
      })
      this.alterarValorBusca(tipo, termo, label, valor, adicionar)
    },
    alterarCarroceria (segmento, tipo, termo, label, valor, adicionar) {
      this.carrocerias = this.carrocerias.map((elemento) => {
        if (segmento.nome === elemento.nome) {
          if (elemento.selecionado) {
            elemento.selecionado = 1
          } else {
            elemento.selecionado = 0
          }
        }
        return elemento
      })
      this.alterarValorBusca(tipo, termo, label, valor, adicionar)
    },
    alterarCategoria (segmento, tipo, termo, label, valor, adicionar) {
      this.categorias = this.categorias.map((elemento) => {
        if (segmento.nome === elemento.nome) {
          if (elemento.selecionado) {
            elemento.selecionado = 1
          } else {
            elemento.selecionado = 0
          }
        }
        return elemento
      })
      this.alterarValorBusca(tipo, termo, label, valor, adicionar)
    },
    alterarTag (segmento, tipo, termo, label, valor, adicionar) {
      this.tags = this.tags.map((elemento) => {
        if (segmento.nome === elemento.nome) {
          if (elemento.selecionado) {
            elemento.selecionado = 1
          } else {
            elemento.selecionado = 0
          }
        }
        return elemento
      })
      this.alterarValorBusca(tipo, termo, label, valor, adicionar)
    },
    alterarFinalPlaca (segmento, tipo, termo, label, valor, adicionar) {
      this.finaisPlacas = this.finaisPlacas.map((elemento) => {
        if (segmento.value === elemento.value) {
          if (elemento.selecionado) {
            elemento.selecionado = 1
          } else {
            elemento.selecionado = 0
          }
        }
        return elemento
      })
      this.alterarValorBusca(tipo, termo, label, valor, adicionar)
    },
    alterarValorBusca (tipo, termo, label, valor, adicionar) {
      if (adicionar) {
        this.adicionarBusca(tipo, termo, label, valor)
      } else {
        this.removerBusca(tipo, termo, label, valor)
      }
    },
    popularAnuncios (retorno, buscaInicial) {
      const items = retorno.hits.hits.map((item) => item._source)
      console.log(items)
      if (buscaInicial) {
        this.anuncios = items
      } else {
        this.anuncios = this.anuncios.concat(items)
      }
      console.log(this.anuncios)

      this.quantidade = retorno.hits.total.value
      this.carregandoBusca = false
    },
    popularPossiveis (retorno) {
      this.carregandoBusca = false
      this.marcas = retorno.aggregations.marcas_nome.buckets.map((item) => {
        const marca = {
          nome: item.key,
          chave_interna: item.key.toLowerCase(),
          ativo: true,
          selecionado: 0
        }
        return marca
      }).sort((a, b) => (a.nome < b.nome) ? -1 : 1)
      this.marcas = this.marcas.map(m => {
        const marca = retorno.aggregations.marcas_nome.buckets.find(marca => marca.key === m.nome)
        if (marca) {
          m.ativo = true
          m.quantidade = marca.doc_count
        } else {
          m.ativo = false
          m.quantidade = 0
        }
        return m
      })
      this.faixa_parcela = retorno.aggregations.faixa_parcela.buckets.map((item) => {
        const faixaParcela = {
          nome: (!item.from ? 'Até R$ ' + item.to.toLocaleString() : (!item.to ? 'Acima de R$ ' + item.from.toLocaleString() : 'R$ ' + item.from.toLocaleString() + ' até R$ ' + item.to.toLocaleString())) + ' (' + item.doc_count + ')',
          chave_interna: item.key,
          ativo: true,
          selecionado: this.faixa_parcela.find(f => f.chave_interna === item.key && f.selecionado) ? 1 : 0,
          quantidade: item.doc_count,
          from: item.from ? item.from : 0,
          to: item.to ? item.to : 999999
        }
        return faixaParcela
      })
      this.faixa_km = retorno.aggregations.faixa_km.buckets.map((item) => {
        const faixaKm = {
          nome: (!item.from ? 'Até ' + item.to.toLocaleString() + 'Km' : (!item.to ? 'Acima de ' + item.from.toLocaleString() + 'Km' : item.from.toLocaleString() + ' até R$ ' + item.to.toLocaleString() + 'Km')) + ' (' + item.doc_count + ')',
          chave_interna: item.key,
          ativo: true,
          selecionado: this.faixa_parcela.find(f => f.chave_interna === item.key && f.selecionado) ? 1 : 0,
          quantidade: item.doc_count,
          from: item.from ? item.from : 0,
          to: item.to ? item.to : 999999
        }
        return faixaKm
      })
      this.faixa_valor = retorno.aggregations.faixa_valor.buckets.map((item) => {
        const faixaValor = {
          nome: (!item.from ? 'Até R$ ' + item.to.toLocaleString() : (!item.to ? 'Acima de R$ ' + item.from.toLocaleString() : 'R$ ' + item.from.toLocaleString() + ' até R$ ' + item.to.toLocaleString())) + ' (' + item.doc_count + ')',
          chave_interna: item.key,
          ativo: true,
          selecionado: this.faixa_valor.find(f => f.chave_interna === item.key && f.selecionado) ? 1 : 0,
          quantidade: item.doc_count,
          from: item.from ? item.from : 0,
          to: item.to ? item.to : 999999
        }
        return faixaValor
      })
      this.finaisPlacas = retorno.aggregations.finais_placa.buckets.map((item) => {
        const finalPlaca = {
          nome: item.key.replace('_', ' e ') + ' (' + item.doc_count + ')',
          value: item.key,
          selecionado: this.finaisPlacas.find(f => f.value === item.key && f.selecionado) ? 1 : 0,
          quantidade: item.doc_count
        }
        return finalPlaca
      }).sort((a, b) => a.value < b.value ? -1 : 1)

      const listaMarcaModelo = []
      retorno.aggregations.marca_modelo_versao.buckets.forEach((item) => {
        const marcaModelo = item.key.split('|')[0] + '|' + item.key.split('|')[1]
        if (listaMarcaModelo.indexOf(marcaModelo) === -1) {
          listaMarcaModelo.push(marcaModelo)
        }
      })
      this.modelos = listaMarcaModelo.map((item) => {
        const marcaNome = item.split('|')[0]
        const modeloNome = item.split('|')[1]
        const modelo = {
          nome: modeloNome,
          chave_interna: modeloNome.toLowerCase(),
          marca: marcaNome,
          ativo: true,
          selecionado: this.modelos.find(f => f.chave_interna === modeloNome.toLowerCase() && f.marca === marcaNome && f.selecionado) ? 1 : 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        }
        return modelo
      }).sort((a, b) => a.nome.localeCompare(b.nome))
      this.versoes = retorno.aggregations.marca_modelo_versao.buckets.map((item) => {
        const marcaNome = item.key.split('|')[0]
        const modeloNome = item.key.split('|')[1]
        const versaoNome = item.key.split('|')[2]
        const versao = {
          nome: versaoNome,
          chave_interna: versaoNome.toLowerCase(),
          modelo: modeloNome,
          marca: marcaNome,
          ativo: true,
          selecionado: this.versoes.find(f => f.chave_interna === versaoNome.toLowerCase() && f.modelo === modeloNome && f.selecionado) ? 1 : 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        }
        return versao
      }).sort((a, b) => a.nome.localeCompare(b.nome))
      this.categorias = retorno.aggregations.categorias_nome.buckets.map((item) => {
        const categoria = {
          nome: item.key + ' (' + item.doc_count + ')',
          chave_interna: item.key,
          ativo: true,
          selecionado: this.categorias.find(f => f.chave_interna === item.key && f.selecionado) ? 1 : 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        }
        return categoria
      }).sort((a, b) => a.nome.localeCompare(b.nome))
      this.tags = retorno.aggregations.tags_nome.buckets.map((item) => {
        const tag = {
          nome: item.key + ' (' + item.doc_count + ')',
          chave_interna: item.key,
          ativo: true,
          selecionado: this.tags.find(f => f.chave_interna === item.key && f.selecionado) ? 1 : 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        }
        return tag
      }).sort((a, b) => a.nome.localeCompare(b.nome))
      this.carrocerias = retorno.aggregations.carrocerias_nome.buckets.map((item) => {
        const leilao = {
          nome: item.key + ' (' + item.doc_count + ')',
          chave_interna: item.key,
          ativo: true,
          selecionado: this.carrocerias.find(f => f.chave_interna === item.key && f.selecionado) ? 1 : 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        }
        return leilao
      }).sort((a, b) => a.nome.localeCompare(b.nome))
      this.combustiveis = retorno.aggregations.combustiveis.buckets.map((item) => {
        const combustivel = {
          nome: item.key + ' (' + item.doc_count + ')',
          chave_interna: item.key,
          ativo: true,
          selecionado: this.combustiveis.find(f => f.chave_interna === item.key && f.selecionado) ? 1 : 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        }
        return combustivel
      }).sort((a, b) => a.nome.localeCompare(b.nome))

      this.opcionais = retorno.aggregations.acessorios.buckets.map((item) => {
        const opcional = {
          nome: item.key + ' (' + item.doc_count + ')',
          chave_interna: item.key,
          ativo: true,
          selecionado: this.opcionais.find(f => f.chave_interna === item.key && f.selecionado) ? 1 : 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        }
        return opcional
      }).sort((a, b) => a.nome.localeCompare(b.nome))
      this.cambios = retorno.aggregations.cambios.buckets.map((item) => {
        const cambio = {
          nome: item.key + ' (' + item.doc_count + ')',
          chave_interna: item.key,
          ativo: true,
          selecionado: this.cambios.find(f => f.chave_interna === item.key && f.selecionado) ? 1 : 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        }
        return cambio
      }).sort((a, b) => a.nome.localeCompare(b.nome))
      this.cores = retorno.aggregations.cor.buckets.map((item) => {
        const cor = {
          nome: item.key + ' (' + item.doc_count + ')',
          chave_interna: item.key,
          ativo: true,
          selecionado: this.cores.find(f => f.chave_interna === item.key && f.selecionado) ? 1 : 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        }
        return cor
      }).sort((a, b) => a.nome.localeCompare(b.nome))
      this.locais = retorno.aggregations.proprietario_razao_social.buckets.map((item) => {
        const local = {
          nome: item.key + ' (' + item.doc_count + ')',
          chave_interna: item.key,
          ativo: true,
          selecionado: this.locais.find(f => f.chave_interna === item.key && f.selecionado) ? 1 : 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        }
        return local
      }).sort((a, b) => a.nome.localeCompare(b.nome))
      // this.valor_parcela_inicial.min = parseInt(retorno.aggregations.menor_valor_parcela.value)
      // this.valor_parcela_inicial.max = parseInt(retorno.aggregations.maior_valor_parcela.value)
      // if ((retorno.aggregations.menor_valor_venda_pf.value !== null) && retorno.aggregations.maior_valor_venda_pf.value) {
      //   this.preco.min = parseInt(retorno.aggregations.menor_valor_venda_pf.value)
      //   this.preco.max = parseInt(retorno.aggregations.maior_valor_venda_pf.value)
      //   this.preco_inicial.min = parseInt(retorno.aggregations.menor_valor_venda_pf.value)
      //   this.preco_inicial.max = parseInt(retorno.aggregations.maior_valor_venda_pf.value)
      //   this.valor_pf_inicial.min = parseInt(retorno.aggregations.menor_valor_venda_pf.value)
      //   this.valor_pf_inicial.max = parseInt(retorno.aggregations.maior_valor_venda_pf.value)
      // }
      // if ((retorno.aggregations.menor_valor_venda_pcd.value !== null) && retorno.aggregations.maior_valor_venda_pcd.value) {
      //   this.valor_pcd_inicial.min = parseInt(retorno.aggregations.menor_valor_venda_pcd.value)
      //   this.valor_pcd_inicial.max = parseInt(retorno.aggregations.maior_valor_venda_pcd.value)
      // }
      // if ((retorno.aggregations.menor_valor_venda_pj.value !== null) && retorno.aggregations.maior_valor_venda_pj.value) {
      //   this.valor_pj_inicial.min = parseInt(retorno.aggregations.menor_valor_venda_pj.value)
      //   this.valor_pj_inicial.max = parseInt(retorno.aggregations.maior_valor_venda_pj.value)
      // }
      // if (retorno.aggregations.menor_ano_modelo.value && retorno.aggregations.maior_ano_modelo.value) {
      //   this.ano.min = retorno.aggregations.menor_ano_modelo.value
      //   this.ano.max = retorno.aggregations.maior_ano_modelo.value
      // }
      // if (retorno.aggregations.menor_km.value && retorno.aggregations.maior_km.value) {
      //   this.km.max = parseInt(retorno.aggregations.maior_km.value)
      // }
    },
    async tratarBusca () {
      let valorSetar = {}
      console.log(this.requisicoesBusca)
      this.requisicoesBusca.forEach((elemento) => {
        if (!valorSetar[elemento.termo] && (elemento.tipo !== 'multiplo')) {
          valorSetar[elemento.termo] = elemento.label + '|'
        }
        if (elemento.tipo === 'multiplo') {
          if (!valorSetar[elemento.termo]) {
            valorSetar[elemento.termo] = ((typeof elemento.label === 'string') ? elemento.label.replace(/ /g, ',,,').replace(/-/g, '...') : elemento.label) + ' ' + '|' + ((typeof elemento.valor === 'string') ? elemento.valor.replace(/ /g, ',,,').replace(/-/g, '...') : elemento.valor) + ' '
          } else if (valorSetar[elemento.termo].indexOf(elemento.label) === -1) {
            valorSetar[elemento.termo] = ((typeof elemento.label === 'string') ? elemento.label.replace(/ /g, ',,,').replace(/-/g, '...') : elemento.label) + ' ' + '-' + valorSetar[elemento.termo].split('|')[0] + '|' + valorSetar[elemento.termo].split('|')[1] + ((typeof elemento.valor === 'string') ? elemento.valor.replace(/ /g, ',,,').replace(/-/g, '...') : elemento.valor) + ' '
          }
        } else if (elemento.tipo === 'range') {
          if ((typeof elemento.valor.min === 'string')) {
            elemento.valor.min = elemento.valor.min.replace('R$', '').replace('.', '')
            elemento.valor.min = parseInt(elemento.valor.min)
          }

          if ((typeof elemento.valor.max === 'string')) {
            elemento.valor.max = elemento.valor.max.replace('R$', '').replace('.', '')
            elemento.valor.max = parseInt(elemento.valor.max)
          }

          valorSetar[elemento.termo] = elemento.label + '|' + elemento.valor.min + ',' + elemento.valor.max
        } else if (elemento.tipo === 'exata') {
          valorSetar[elemento.termo] = elemento.label + '|' + elemento.valor
        } else if (elemento.tipo === 'geral') {
          let listaBuscaMaior = []

          let termos = elemento.valor.split(' ')

          let listaBusca = []

          termos.forEach((elemento) => {
            listaBusca.push('nome:*' + elemento + '*')
            listaBusca.push('marca_objeto.marca_nome:*' + elemento + '*')
            listaBusca.push('modelo_objeto.modelo_nome:*' + elemento + '*')
            listaBusca.push('cor_objeto.cor_nome:*' + elemento + '*')
            listaBusca.push('estoque_local.razao_social:*' + elemento + '*')
            listaBusca.push('carroceria_objeto.carroceria_nome:*' + elemento + '*')
            if (!isNaN(elemento)) {
              listaBusca.push('ano_modelo:' + elemento)
            }
            listaBuscaMaior.push('(' + listaBusca.join(' OR ') + ')')

            listaBusca = []
          })

          valorSetar[elemento.termo] += elemento.valor.toLowerCase()
        }
      })

      let params = new URLSearchParams()

      for (let [chave, valor] of Object.entries(valorSetar)) {
        if (params.has(chave)) {
          params.set(chave, valor)
        } else {
          params.append(chave, valor)
        }
      }
      this.modoBusca = false
      this.scrolltoTop()
      this.scrollFilterToTop()
      window.history.replaceState({}, '', `${location.pathname}?${params}`)
      this.pagina = 1
      await this.buscar(true)
    },
    adicionarBusca (tipo, termo, label, valor) {
      this.requisicoesBusca = this.requisicoesBusca.filter((elemento) => !(((elemento.termo === termo) && (elemento.valor === valor)) || ((elemento.termo === termo) && ((elemento.tipo === 'exata') || (elemento.tipo === 'range'))) || ((termo === 'busca_geral') && (elemento.termo === 'busca_geral'))))
      this.requisicoesBusca.push({ tipo: tipo, termo: termo, label: label, valor: valor })
      this.tratarBusca()
    },
    removerBusca (tipo, termo, label, valor) {
      this.requisicoesBusca = this.requisicoesBusca.filter((item) => (termo !== item.termo) || (valor !== item.valor))
      if ((tipo === 'exata') || (tipo === 'range')) {
        this.requisicoesBusca = this.requisicoesBusca.filter((item) => termo !== item.termo)
      }
      if (termo === 'dados.FIPE_MARCA_BUSCA.keyword') {
        this.requisicoesBusca = this.requisicoesBusca.filter((item) => (item.termo !== 'dados.FIPE_MODELO_BUSCA.keyword') && (item.termo !== 'dados.FIPE_MODELO_BUSCA.keyword') && (item.termo !== 'dados.FIPE_VERSAO_BUSCA.keyword'))
      }
      this.tratarBusca()
    },
    ordenar (ordenacao) {
      if (ordenacao.nome === 'created_at') {
        this.elasticSearchModel.busca.sort = [{ situacao_id: 'asc' }, { [ordenacao.nome]: ordenacao.tipo }]
      } else {
        this.elasticSearchModel.busca.sort = [{ [ordenacao.nome]: ordenacao.tipo }]
      }

      let url = new URL(window.location.href)
      let params = new URLSearchParams(url.search.slice(1))

      params.delete('ordenacao')

      params.append('ordenacao', ordenacao.nome + ' ' + ordenacao.tipo)

      window.history.replaceState({}, '', `${location.pathname}?${params}`)

      this.pagina = 1
      console.log(this.pagina)
      this.buscar(true)
    },
    removermarca (index) {
      const marcasRemoverIndice = []
      this.buscaLabels.filter((item, indice) => {
        if (this.buscaLabels[indice] && this.marcasSelecionadas[index]) {
          if (this.marcasSelecionadas[index].nome === this.buscaLabels[indice].trim()) {
            marcasRemoverIndice.push(indice)
          }
        }
      })
      marcasRemoverIndice.forEach((item) => this.removerBuscaPorIndice(item))
      if (this.marcasSelecionadas.length < this.modelosSelecionados.length) {
        this.modelosSelecionados.splice(index, 1)
      }
    },
    removermodelo (index) {
      this.buscaLabels.filter((item, indice) => {
        if (this.buscaLabels[indice]) {
          if (this.modelosSelecionados[index].nome === this.buscaLabels[indice].trim()) {
            console.log(indice)
            this.removerBuscaPorIndice(indice)
          }
        }
      })
    },
    removerversao (index) {
      this.buscaLabels.filter((item, indice) => {
        if (this.versoesSelecionadas[index].nome === this.buscaLabels[indice].replace(' ', '')) {
          this.removerBuscaPorIndice(indice)
        }
      })
    },
    async removerBuscaPorIndice (indice) {
      if (this.requisicoesBusca[indice] && (this.requisicoesBusca[indice].termo === 'marca_objeto.marca_nome.keyword')) {
        const indiceMarca = this.marcasSelecionadas.findIndex((elemento) => {
          return this.requisicoesBusca[indice].valor === elemento.nome
        })
        this.marcasSelecionadas.splice(indiceMarca, 1)

        if (this.modelosSelecionados.find((elementoModelo) => {
          return elementoModelo && (this.requisicoesBusca[indice].valor === elementoModelo.marca)
        })) {
          const modeloSelecionado = this.modelosSelecionados.find((elementoModelo) => {
            if (elementoModelo) {
              return this.requisicoesBusca[indice].valor === elementoModelo.marca
            }
          })
          if (modeloSelecionado) {
            const indiceModelo = this.requisicoesBusca.findIndex((elemento) => elemento.valor === modeloSelecionado.nome)
            await this.removerBuscaPorIndice(indiceModelo)
          }
        }
      } else if (this.requisicoesBusca[indice] && (this.requisicoesBusca[indice].termo === 'modelo_objeto.modelo_nome.keyword')) {
        const indiceModelo = this.modelosSelecionados.findIndex((elemento, indiceModelo) => {
          if (this.requisicoesBusca[indice].valor === elemento.nome) {
            return true
          } else {
            return false
          }
        })
        this.modelosSelecionados.splice(indiceModelo, 1, '')

        if (this.versoesSelecionadas.find((elementoVersao) => {
          return this.requisicoesBusca[indice].valor === elementoVersao.modelo
        })) {
          const indiceVersao = this.requisicoesBusca.findIndex((elemento) => elemento.valor === this.versoesSelecionadas.find((elementoVersao) => {
            return this.requisicoesBusca[indice].valor === elementoVersao.modelo
          })?.nome)
          await this.removerBuscaPorIndice(indiceVersao)
        }
      } else if (this.requisicoesBusca[indice] && (this.requisicoesBusca[indice].termo === 'versao_objeto.versao_nome.keyword')) {
        this.versoesSelecionadas = this.versoesSelecionadas.map((elemento) => this.requisicoesBusca[indice].valor !== elemento.nome ? elemento : '')
      }
      if (this.requisicoesBusca[indice] && (this.requisicoesBusca[indice].label === 'Carro CPD')) {
        this.pcd = 0
      }
      if (this.requisicoesBusca[indice] && (this.requisicoesBusca[indice].label === 'Pessoa Jurídica')) {
        this.pj = 0
      }

      this.requisicoesBusca = this.requisicoesBusca.filter((item, i) => {
        if (indice === i) {
          if (this.zero_km_true === true) {
            this.zero_km_true = false
          } else if (this.zero_km_false === true) {
            this.zero_km_false = false
          } else if (this.outlet_true === true) {
            this.outlet_true = false
          } else if (this.outlet_false === true) {
            this.outlet_false = false
          } else if (this.ipvapago_true === true) {
            this.ipvapago_true = false
          } else if (this.ipvapago_false === true) {
            this.ipvapago_false = false
          } else if (this.aplicativo_true === true) {
            this.aplicativo_true = false
          } else if (this.aplicativo_false === true) {
            this.aplicativo_false = false
          } else if (this.blackfriday_true === true) {
            this.blackfriday_true = false
          } else if (this.blackfriday_false === true) {
            this.blackfriday_false = false
          } else if (this.cyberweek_true === true) {
            this.cyberweek_true = false
          } else if (this.cyberweek_false === true) {
            this.cyberweek_false = false
          } else if (this.blindado_true === true) {
            this.blindado_true = false
          } else if (this.blindado_false === true) {
            this.blindado_false = false
          } else if (item.termo.includes('valor_venda_pf')) {
            this.precoInput = { min: '', max: '' }
            // this.preco = { min: 30000, max: 70000 }
          } else if (item.termo.includes('km')) {
            this.kmInput = { min: '', max: '' }
          } else if (item.termo.includes('cambio_objeto.cambio_nome.keyword')) {
            this.cambios = this.cambios.map((cambio, i) => {
              if (item.valor === cambio.nome) {
                cambio.selecionado = 0
              }
              return cambio
            })
          } else if (item.termo.includes('combustivel_objeto.combustivel_nome.keyword')) {
            this.combustiveis = this.combustiveis.map((combustivel, i) => {
              if (item.valor === combustivel.nome) {
                combustivel.selecionado = 0
              }
              return combustivel
            })
          } else if (item.termo.includes('carroceria_objeto.carroceria_nome.keyword')) {
            this.carrocerias = this.carrocerias.map((carroceria, i) => {
              if (item.valor === carroceria.nome) {
                carroceria.selecionado = 0
              }
              return carroceria
            })
          } else if (item.termo.includes('placa_final.keyword')) {
            let placaprov = item.valor.replace('_', ' e ')
            this.finaisPlacas = this.finaisPlacas.map((placa, i) => {
              if (placaprov === placa.nome) {
                placa.selecionado = 0
              }
              return placa
            })
          } else if (item.termo.includes('acessorios_busca_nome')) {
            this.opcionais = this.opcionais.map((opcional, i) => {
              if (item.valor === opcional.nome) {
                opcional.selecionado = 0
              }
              return opcional
            })
          } else if (item.termo.includes('categoria_objeto.categoria_nome.keyword')) {
            this.categorias = this.categorias.map((categoria, i) => {
              if (item.valor === categoria.nome) {
                categoria.selecionado = 0
              }
              return categoria
            })
          } else if (item.termo.includes('tags_busca_nome.keyword')) {
            this.tags = this.tags.map((tag, i) => {
              if (item.valor === tag.nome) {
                tag.selecionado = 0
              }
              return tag
            })
          }
          return item
        } else if (item.termo.includes('cor_objeto.cor_nome.keyword')) {
          this.cores = this.cores.map((cor, i) => {
            if (item.valor === cor.nome) {
              cor.selecionado = 0
            }
            return cor
          })
        } else if (item.termo.includes('estoque_local.razao_social.keyword')) {
          this.locais = this.locais.map((local, i) => {
            if (item.valor === local.razao_social) {
              local.selecionado = 0
            }
            return local
          })
        } else {
          return item
        }
      })

      this.requisicoesBusca.splice(indice, 1)
      await this.tratarBusca()
      if (this.marcasSelecionadas.length === 0) {
        this.marcasSelecionadas = [{
          nome: '',
          chave_interna: '',
          ativo: true,
          selecionado: 0,
          created_at: '2020-05-18 13:25:32',
          updated_at: '2020-05-18 13:25:32'
        }]
      }
    },
    removerBuscaTodos () {
      this.buscarPossiveis()
      this.requisicoesBusca = []
    },
    definirVerTodos (tituloVoltar, campo) {
      this.verTodos.tituloVoltar = tituloVoltar
      this.verTodos.campo = campo
      this.modoBusca = true
    },
    voltarFiltro () {
      this.modoBusca = false
    },
    verMaisMarcas () {
      const _total = 6
      this.paginaAtualMarcas++
      const marcas = clone(this.marcas)
      this.marcasDisplay = marcas.slice(0, this.paginaAtualMarcas * _total)
      if (this.marcasDisplay.length === this.marcas.length) {
        this.fimPaginacaoMarcas = true
      }
    },
    verMaisOpcionais () {
      const _total = 10
      this.paginaAtualOpcionais++
      const opcionais = clone(this.opcionais)
      this.opcionaisDisplay = opcionais.slice(
        0,
        this.paginaAtualOpcionais * _total
      )
      if (this.opcionaisDisplay.length === this.opcionais.length) {
        this.fimPaginacaoOpcionais = true
      }
    },
    verMaisCores () {
      const _total = 6
      this.paginaAtualCores++
      const cores = clone(this.cores)
      this.coresDisplay = cores.slice(0, this.paginaAtualCores * _total)
      if (this.coresDisplay.length === this.cores.length) {
        this.fimPaginacaoCores = true
      }
    },
    removeLabels () {
      this.requisicoesBusca = []
      this.marcasSelecionadas = [{
        nome: '',
        chave_interna: '',
        ativo: true,
        selecionado: 0,
        created_at: '2020-05-18 13:25:32',
        updated_at: '2020-05-18 13:25:32'
      }]
      this.modelosSelecionados = []
      this.versoesSelecionadas = []
      this.faixa_parcela = []
      this.faixa_valor = []
      this.faixa_km = []
      this.tags = []
      this.zero_km_true = false
      this.outlet_true = false
      this.outlet_false = false
      this.ipvapago_true = false
      this.ipvapago_false = false
      this.aplicativo_true = false
      this.aplicativo_false = false
      this.blackfriday_true = false
      this.blackfriday_false = false
      this.cyberweek_true = false
      this.cyberweek_false = false
      this.blindado_true = false
      this.blindado_false = false
      this.cambios.selecionado = 0
      this.combustiveis.forEach((combustivel) => {
        combustivel.selecionado = 0
      })
      this.categorias.forEach((categoria) => {
        categoria.selecionado = 0
      })
      this.opcionais.forEach((opcional) => {
        opcional.selecionado = 0
      })
      this.cores.forEach((cor) => {
        cor.selecionado = 0
      })
      this.locais.forEach((local) => {
        local.selecionado = 0
      })
      this.finaisPlacas.forEach((placa) => {
        placa.selecionado = 0
      })
      this.carrocerias.forEach((carroceria) => {
        carroceria.selecionado = 0
      })
      this.pagina = 1
      this.buscar(true)
    }
  },
  beforeDestroy () {
    EventBus.$off('busca_geral')
    EventBus.$off('busca_marca_modelo')
    EventBus.$off('faixa_preco')
    if (this.intervalMarcaModelo) {
      clearInterval(this.intervalMarcaModelo)
    }
  }
}
